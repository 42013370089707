import React from "react";

import styles from "./HomeHeroBack.module.css";

export const HomeHeroBack = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.morphYellow}></div>
    <div className={styles.morphGreen}></div>
  </div>
);
