import React from "react";

import styles from "./HomeEnhance.module.css";

export const HomeEnhance = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.line}>
      <svg
        width="200"
        height="2"
        viewBox="0 0 200 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1"
          x2="200"
          y2="1"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="2 6"
        />
      </svg>
    </div>
    <div className={styles.line2}>
      <svg
        width="200"
        height="2"
        viewBox="0 0 200 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1"
          x2="200"
          y2="1"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="2 6"
        />
      </svg>
    </div>
    <div className={styles.card}></div>
    <div className={styles.bank}>
      <svg
        width="39"
        height="43"
        viewBox="0 0 39 43"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 18.14V42H12.7111M1 18.14V12.1071L18.5438 2L37.0372 12.1071V18.14M1 18.14H12.7111M37.0372 18.14V42H24.4222M37.0372 18.14H24.4222M24.4222 18.14V42M24.4222 18.14H12.7111M24.4222 42H12.7111M12.7111 18.14V42"
          stroke="black"
          strokeWidth="2"
        />
      </svg>
    </div>
    <div className={styles.phone}>
      <svg
        width="42"
        height="62"
        viewBox="0 0 42 62"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="15.5762"
          y="15.5758"
          width="24.4273"
          height="44.5624"
          rx="3"
          stroke="black"
          strokeWidth="2"
        />
        <path
          d="M25.9551 19.8162H29.7304"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.4395 16.7302C10.4395 13.2551 13.2566 10.438 16.7317 10.438"
          stroke="black"
          strokeWidth="2"
          className={styles.wifi1}
        />
        <path
          d="M5.40625 16.7313C5.40625 10.4761 10.4771 5.4053 16.7322 5.4053"
          stroke="black"
          strokeWidth="2"
          className={styles.wifi2}
        />
        <path
          d="M1 16.7305C1 8.0428 8.0428 1 16.7305 1"
          stroke="black"
          strokeWidth="2"
          className={styles.wifi3}
        />
      </svg>
    </div>
    <div className={styles.overlayTop}></div>
    <div className={styles.overlayLeft}></div>
  </div>
);
