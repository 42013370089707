import React from "react";

import styles from "./HomePlan.module.css";

export const HomePlan = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.logo}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          cx="40"
          cy="40"
          r="28.2843"
          transform="rotate(45 40 40)"
          fill="#55F5A3"
        />
        <path
          d="M42.5376 37.6916C43.9367 39.0906 43.9367 41.3589 42.5376 42.7579C41.1386 44.1569 38.8704 44.1569 37.4713 42.7579C36.0723 41.3589 36.0723 39.0906 37.4713 37.6916C38.8704 36.2926 41.1386 36.2926 42.5376 37.6916Z"
          fill="black"
        />
        <path
          d="M53.761 43.8276C54.7156 43.8574 55.6568 43.4901 56.3854 42.7615C57.7831 41.3638 57.7831 39.0948 56.3839 37.6956C54.9862 36.2979 52.7172 36.2979 51.3195 37.6956C50.5909 38.4242 50.188 39.4189 50.2519 40.3601C50.2995 41.2315 50.1062 42.1058 49.4519 42.76C48.7977 43.4143 47.8996 43.5302 46.9941 43.5585C46.1326 43.5254 45.317 43.8209 44.6344 44.4C44.5547 44.4669 44.4773 44.5378 44.4024 44.6127C44.3996 44.6154 44.3969 44.6182 44.3942 44.6209C44.392 44.6231 44.3897 44.6254 44.3875 44.6276C44.1982 44.8169 44.0345 45.0221 43.8965 45.2389C43.4942 45.8619 43.2888 46.5833 43.3348 47.2773C43.3824 48.1486 43.1891 49.0229 42.5348 49.6771C41.8806 50.3314 40.9825 50.4474 40.0769 50.4756C39.109 50.4384 38.199 50.8161 37.4704 51.5447C36.0727 52.9424 36.0727 55.2114 37.4734 56.6091C38.8711 58.0068 41.1386 58.0083 42.5378 56.6091C43.2664 55.8805 43.6426 54.8947 43.5994 53.9446C43.592 53.1283 43.7496 52.1974 44.4039 51.5432C45.0581 50.889 45.8759 50.7254 46.8052 50.7447H46.8439C47.5925 50.7681 48.3329 50.5472 48.969 50.0994C49.1398 49.9802 49.3025 49.8444 49.4549 49.692C49.4575 49.6895 49.46 49.6869 49.4625 49.6844C49.4644 49.6825 49.4664 49.6805 49.4683 49.6786C49.835 49.3119 50.1055 48.8855 50.2798 48.4308C50.4563 47.978 50.5379 47.4984 50.5166 47.0275C50.5091 46.2111 50.6667 45.2803 51.321 44.6261C51.9752 43.9719 52.793 43.8083 53.7224 43.8276H53.761Z"
          fill="black"
        />
        <path
          d="M39.9088 29.9755C40.8634 30.0052 41.8047 29.6379 42.5332 28.9093C43.9309 27.5117 43.9309 25.2426 42.5318 23.8434C41.1341 22.4457 38.865 22.4457 37.4673 23.8434C36.7387 24.572 36.3358 25.5668 36.3997 26.508C36.4473 27.3793 36.254 28.2536 35.5998 28.9079C34.9455 29.5621 34.0474 29.6781 33.1419 29.7063C32.2804 29.6733 31.4648 29.9688 30.7822 30.5478C30.7026 30.6147 30.6251 30.6856 30.5502 30.7606C30.5475 30.7633 30.5447 30.766 30.542 30.7688C30.5398 30.771 30.5376 30.7732 30.5353 30.7754C30.346 30.9647 30.1824 31.1699 30.0443 31.3867C29.6421 32.0098 29.4366 32.7311 29.4826 33.4251C29.5302 34.2964 29.3369 35.1707 28.6826 35.825C28.0284 36.4792 27.1303 36.5952 26.2248 36.6234C25.2568 36.5863 24.3468 36.964 23.6182 37.6925C22.2205 39.0902 22.2205 41.3593 23.6212 42.757C25.0189 44.1547 27.2864 44.1562 28.6856 42.757C29.4142 42.0284 29.7904 41.0426 29.7473 40.0924C29.7398 39.2761 29.8974 38.3453 30.5517 37.6911C31.2059 37.0368 32.0237 36.8733 32.9531 36.8926H32.9917C33.7404 36.9159 34.4808 36.695 35.1168 36.2472C35.2876 36.1281 35.4503 35.9923 35.6027 35.8398C35.6053 35.8373 35.6078 35.8348 35.6103 35.8322C35.6123 35.8303 35.6142 35.8284 35.6161 35.8265C35.9828 35.4598 36.2533 35.0334 36.4277 34.5786C36.6041 34.1259 36.6858 33.6462 36.6644 33.1753C36.657 32.359 36.8146 31.4282 37.4688 30.7739C38.1231 30.1197 38.9409 29.9561 39.8702 29.9755H39.9088Z"
          fill="black"
        />
      </svg>
    </div>
    <div className={styles.line}>
      <svg
        width="80"
        height="2"
        viewBox="0 0 80 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1"
          x2="80"
          y2="1"
          stroke="black"
          strokeWidth="2"
          strokeDasharray="2 6"
        />
      </svg>
    </div>
    <div className={styles.cardWrapper}>
      <div className={styles.chip}>
        <svg
          width="42"
          height="35"
          viewBox="0 0 42 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.250427"
            y="0.25"
            width="41.4997"
            height="34.4997"
            rx="2.75"
            fill="#DDDDDD"
            stroke="#AAAAAA"
            strokeWidth="0.5"
          />
          <path
            d="M14.4374 11.8115L9.62493 7.87398L0 7.87398M14.4374 11.8115L14.4374 17.4989M14.4374 11.8115L20.5624 11.8115M26.6873 11.8115V17.4989M26.6873 11.8115L31.4998 7.87398L41.1247 7.87398M26.6873 11.8115H20.5624M26.6873 23.1864V17.4989M26.6873 23.1864L31.4998 27.9988L41.1247 27.9988M26.6873 23.1864H20.5624M14.4374 23.1864V17.4989M14.4374 23.1864L9.62493 27.9988H0M14.4374 23.1864H20.5624M14.4374 17.4989H0M26.6873 17.4989L41.1247 17.4989M20.5624 23.1864L20.5624 34.5613M20.5624 11.8115V0.436539"
            stroke="#AAAAAA"
            strokeWidth="0.5"
          />
        </svg>
      </div>
      <div className={styles.contactless}>
        <svg
          width="14"
          height="23"
          viewBox="0 0 14 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.85866 1.58409C6.85866 1.9131 6.982 2.2008 7.22869 2.4478C12.1222 7.34136 12.1632 15.36 7.22869 20.2535C6.73531 20.7469 6.73531 21.5282 7.22869 21.9806C7.72206 22.474 8.50339 22.474 8.9558 21.9806C14.8361 16.1004 14.7951 6.55997 8.9558 0.720684C8.46243 0.227311 7.6811 0.227311 7.22869 0.720684C6.982 0.96737 6.85866 1.29637 6.85866 1.58409Z"
            fill="black"
          />
          <path
            d="M4.10352 18.8963C4.59689 19.3896 5.37822 19.3896 5.83063 18.8963C7.84567 16.8812 8.95581 14.2084 8.95581 11.371C8.95581 8.53358 7.84545 5.86074 5.83063 3.8457C5.33726 3.35233 4.55593 3.35233 4.10352 3.8457C3.85683 4.09239 3.73349 4.38037 3.73349 4.7094C3.73349 5.03844 3.85683 5.32611 4.10352 5.57311C5.66622 7.13581 6.48858 9.19189 6.48858 11.3713C6.48858 13.5508 5.62488 15.6069 4.10352 17.1696C3.65116 17.6627 3.65116 18.444 4.10352 18.8964L4.10352 18.8963Z"
            fill="black"
          />
          <path
            d="M2.74652 6.92992C2.25315 6.43655 1.47182 6.43655 1.0194 6.92992C0.772718 7.17661 0.649375 7.46459 0.649375 7.79363C0.649375 8.12266 0.772718 8.41033 1.0194 8.65733C2.49979 10.1377 2.49979 12.5638 1.0194 14.0442C0.526031 14.5376 0.526031 15.3189 1.0194 15.7713C1.51278 16.2647 2.29411 16.2647 2.74652 15.7713C5.17263 13.3449 5.17263 9.39737 2.74652 6.93014L2.74652 6.92992Z"
            fill="black"
          />
        </svg>
      </div>
      <div className={styles.card}></div>
    </div>
  </div>
);
