import React from "react";

import styles from "./HomeGraph.module.css";

export const HomeGraph = (): JSX.Element => (
  <div>
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1"
        y="29"
        width="18"
        height="18"
        rx="1"
        fill="white"
        stroke="white"
        strokeWidth="2"
      />
      <rect
        x="0"
        y="18"
        width="30"
        height="30"
        rx="1"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="2 6"
        className={styles.dashed1}
      />
      <rect
        x="0"
        y="2"
        width="46"
        height="46"
        rx="1"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="2 6"
        className={styles.dashed2}
      />
    </svg>
  </div>
);
