import React from "react";

import styles from "./HomeDash.module.css";

export const HomeDash = (): JSX.Element => (
  <div>
    <svg
      width="64"
      height="48"
      viewBox="0 0 64 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64 12C64 10.8954 63.1046 10 62 10H20C17.7909 10 16 11.7909 16 14V46C16 47.1046 16.8954 48 18 48H62C63.1046 48 64 47.1046 64 46V12Z"
        fill="white"
      />
      <path
        d="M59 20C59 19.4477 58.5523 19 58 19H22C21.4477 19 21 19.4477 21 20V20C21 20.5523 21.4477 21 22 21H58C58.5523 21 59 20.5523 59 20V20Z"
        fill="black"
        className={styles.row}
      />
      <path
        d="M59 26C59 25.4477 58.5523 25 58 25H22C21.4477 25 21 25.4477 21 26V26C21 26.5523 21.4477 27 22 27H58C58.5523 27 59 26.5523 59 26V26Z"
        fill="black"
        className={styles.row2}
      />
      <path
        d="M59 32C59 31.4477 58.5523 31 58 31H22C21.4477 31 21 31.4477 21 32V32C21 32.5523 21.4477 33 22 33H58C58.5523 33 59 32.5523 59 32V32Z"
        fill="black"
        className={styles.row3}
      />
      <path
        d="M59 38C59 37.4477 58.5523 37 58 37H22C21.4477 37 21 37.4477 21 38V38C21 38.5523 21.4477 39 22 39H58C58.5523 39 59 38.5523 59 38V38Z"
        fill="black"
        className={styles.ro4}
      />
      <rect
        x="1"
        y="1"
        width="62"
        height="46"
        rx="1"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="2 6"
        className={styles.dashed}
      />
    </svg>
  </div>
);
