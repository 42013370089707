import React from "react";

import styles from "./HomeBrand.module.css";

export const HomeBrand = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.collateral}>
      <svg
        width="79"
        height="20"
        viewBox="0 0 79 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.every}
      >
        <rect
          x="8.66016"
          y="3.17065"
          width="2"
          height="17"
          transform="rotate(-30 8.66016 3.17065)"
          fill="black"
        />
        <rect
          x="10.3926"
          y="2.17035"
          width="2"
          height="12"
          transform="rotate(60 10.3926 2.17035)"
          fill="black"
        />
        <rect
          x="9.42773"
          y="8.50049"
          width="2"
          height="8"
          transform="rotate(60 9.42773 8.50049)"
          fill="black"
        />
        <rect
          x="15.3926"
          y="10.8306"
          width="2"
          height="12"
          transform="rotate(60 15.3926 10.8306)"
          fill="black"
        />
        <rect
          x="21.5527"
          y="1.5"
          width="2"
          height="12"
          transform="rotate(60 21.5527 1.5)"
          fill="black"
        />
        <rect
          x="24.0527"
          y="5.83093"
          width="2"
          height="8"
          transform="rotate(60 24.0527 5.83093)"
          fill="black"
        />
        <rect
          x="26.5527"
          y="10.1611"
          width="2"
          height="12"
          transform="rotate(60 26.5527 10.1611)"
          fill="black"
        />
        <path
          d="M36.9805 7.99999V6.94999H33.1105V4.94999H36.5005V3.89999H33.1105V1.90999H36.9405V0.859985H31.8105V7.99999H36.9805Z"
          fill="black"
        />
        <path
          d="M40.5028 7.99999H41.9828L44.8128 0.859985H43.4628L41.2828 6.57999H41.2628L39.0928 0.859985H37.6828L40.5028 7.99999Z"
          fill="black"
        />
        <path
          d="M50.9161 7.99999V6.94999H47.0461V4.94999H50.4361V3.89999H47.0461V1.90999H50.8761V0.859985H45.7461V7.99999H50.9161Z"
          fill="black"
        />
        <path
          d="M53.6184 7.99999V5.32999H55.2184L56.8484 7.99999H58.2984L56.5084 5.12999C57.3184 4.86999 58.0284 4.20999 58.0284 3.09999C58.0284 1.63999 57.0484 0.859985 55.3784 0.859985H52.3184V7.99999H53.6184ZM53.6184 4.28999V1.90999H55.2284C56.2184 1.90999 56.7184 2.31999 56.7184 3.09999C56.7184 3.87999 56.2184 4.28999 55.2284 4.28999H53.6184Z"
          fill="black"
        />
        <path
          d="M62.6515 7.99999V5.06999L65.4215 0.859985H63.9715L62.0415 3.90999H62.0215L60.0415 0.859985H58.5615L61.3215 5.06999V7.99999H62.6515Z"
          fill="black"
        />
        <path
          d="M36.9805 19V17.95H33.1105V15.95H36.5005V14.9H33.1105V12.91H36.9405V11.86H31.8105V19H36.9805Z"
          fill="black"
        />
        <path
          d="M43.2628 19H44.7728L41.9328 15.2L44.4528 11.86H43.0528L41.2428 14.3L39.4428 11.86H37.9328L40.4828 15.29L37.6828 19H39.0828L41.1728 16.2L43.2628 19Z"
          fill="black"
        />
        <path
          d="M48.6573 16.37C50.3373 16.37 51.3173 15.61 51.3173 14.12C51.3173 12.63 50.3373 11.86 48.6473 11.86H45.6973V19H46.9973V16.37H48.6573ZM46.9973 15.33V12.91H48.5373C49.5173 12.91 50.0073 13.3 50.0073 14.12C50.0073 14.93 49.5173 15.33 48.5373 15.33H46.9973Z"
          fill="black"
        />
        <path
          d="M57.6153 19V17.95H53.7453V15.95H57.1353V14.9H53.7453V12.91H57.5753V11.86H52.4453V19H57.6153Z"
          fill="black"
        />
        <path
          d="M60.5676 11.86H59.0176V19H60.2876V13.47H60.3076L63.9776 19H65.2076V11.86H63.9476V16.92H63.9276L60.5676 11.86Z"
          fill="black"
        />
        <path
          d="M66.3601 16.84C66.4901 18.41 67.8001 19.12 69.5001 19.12C71.0801 19.12 72.2701 18.26 72.2701 16.96C72.2701 15.81 71.5701 15.23 70.4001 15.01L69.0201 14.74C68.2501 14.59 67.8801 14.4 67.8801 13.81C67.8801 13.18 68.4201 12.73 69.2801 12.73C70.1701 12.73 70.7901 13.12 70.8801 13.82H72.1301C71.9901 12.48 70.9701 11.74 69.2701 11.74C67.7101 11.74 66.5901 12.62 66.5901 13.89C66.5901 15.14 67.4401 15.64 68.4901 15.83L69.8001 16.07C70.6601 16.23 70.9701 16.52 70.9701 17.04C70.9701 17.76 70.3101 18.14 69.4601 18.14C68.5701 18.14 67.7301 17.81 67.6401 16.84H66.3601Z"
          fill="black"
        />
        <path
          d="M78.67 19V17.95H74.8V15.95H78.19V14.9H74.8V12.91H78.63V11.86H73.5V19H78.67Z"
          fill="black"
        />
      </svg>
      <div className={styles.brand2}>
        <div className={styles.square}></div>
        <div className={styles.triangle}></div>
      </div>
      <div className={styles.collateralTitle}>Enjoy your new card!</div>
      <div>
        Questions?
        <br />
        support@everyexpense.com
      </div>
    </div>
    <div className={styles.cards}>
      <div className={styles.card}>
        <div className={styles.chip}>
          <svg
            width="42"
            height="35"
            viewBox="0 0 42 35"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="0.250427"
              y="0.25"
              width="41.4997"
              height="34.4997"
              rx="2.75"
              fill="#DDDDDD"
              stroke="#AAAAAA"
              strokeWidth="0.5"
            />
            <path
              d="M14.4374 11.8115L9.62493 7.87398L0 7.87398M14.4374 11.8115L14.4374 17.4989M14.4374 11.8115L20.5624 11.8115M26.6873 11.8115V17.4989M26.6873 11.8115L31.4998 7.87398L41.1247 7.87398M26.6873 11.8115H20.5624M26.6873 23.1864V17.4989M26.6873 23.1864L31.4998 27.9988L41.1247 27.9988M26.6873 23.1864H20.5624M14.4374 23.1864V17.4989M14.4374 23.1864L9.62493 27.9988H0M14.4374 23.1864H20.5624M14.4374 17.4989H0M26.6873 17.4989L41.1247 17.4989M20.5624 23.1864L20.5624 34.5613M20.5624 11.8115V0.436539"
              stroke="#AAAAAA"
              strokeWidth="0.5"
            />
          </svg>
        </div>
        <div className={styles.contactless}>
          <svg
            width="14"
            height="23"
            viewBox="0 0 14 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.85866 1.58409C6.85866 1.9131 6.982 2.2008 7.22869 2.4478C12.1222 7.34136 12.1632 15.36 7.22869 20.2535C6.73531 20.7469 6.73531 21.5282 7.22869 21.9806C7.72206 22.474 8.50339 22.474 8.9558 21.9806C14.8361 16.1004 14.7951 6.55997 8.9558 0.720684C8.46243 0.227311 7.6811 0.227311 7.22869 0.720684C6.982 0.96737 6.85866 1.29637 6.85866 1.58409Z"
              fill="white"
            />
            <path
              d="M4.10352 18.8963C4.59689 19.3896 5.37822 19.3896 5.83063 18.8963C7.84567 16.8812 8.95581 14.2084 8.95581 11.371C8.95581 8.53358 7.84545 5.86074 5.83063 3.8457C5.33726 3.35233 4.55593 3.35233 4.10352 3.8457C3.85683 4.09239 3.73349 4.38037 3.73349 4.7094C3.73349 5.03844 3.85683 5.32611 4.10352 5.57311C5.66622 7.13581 6.48858 9.19189 6.48858 11.3713C6.48858 13.5508 5.62488 15.6069 4.10352 17.1696C3.65116 17.6627 3.65116 18.444 4.10352 18.8964L4.10352 18.8963Z"
              fill="white"
            />
            <path
              d="M2.74652 6.92992C2.25315 6.43655 1.47182 6.43655 1.0194 6.92992C0.772718 7.17661 0.649375 7.46459 0.649375 7.79363C0.649375 8.12266 0.772718 8.41033 1.0194 8.65733C2.49979 10.1377 2.49979 12.5638 1.0194 14.0442C0.526031 14.5376 0.526031 15.3189 1.0194 15.7713C1.51278 16.2647 2.29411 16.2647 2.74652 15.7713C5.17263 13.3449 5.17263 9.39737 2.74652 6.93014L2.74652 6.92992Z"
              fill="white"
            />
          </svg>
        </div>
        <div className={styles.name}>
          <div>Emily Smith</div>
        </div>
        <div className={styles.debit}>
          <div>Debit</div>
        </div>
        <div className={styles.logo}>
          <svg
            width="70"
            height="23"
            viewBox="0 0 70 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.visa}
          >
            <path
              d="M26.578 0.399071L17.4135 22.2365H11.4344L6.92449 4.80923C6.65069 3.73582 6.41262 3.34258 5.57992 2.89035C4.22039 2.15374 1.9753 1.46265 0 1.03372L0.134166 0.399071H9.75875C10.9856 0.399071 12.0884 1.21469 12.367 2.62563L14.7488 15.2619L20.6353 0.398707H26.578V0.399071ZM50.0053 15.1068C50.0293 9.3432 42.0253 9.02569 42.0804 6.45103C42.0975 5.66745 42.8445 4.83436 44.4797 4.62171C45.2901 4.51576 47.5232 4.43492 50.0559 5.599L51.0494 0.968181C49.6884 0.474805 47.9374 0 45.7586 0C40.1678 0 36.2332 2.96827 36.2 7.21858C36.1639 10.3624 39.0084 12.1167 41.1514 13.1613C43.356 14.2311 44.0958 14.9185 44.0874 15.8754C44.0717 17.3403 42.329 17.9869 40.7004 18.0124C37.8574 18.0561 36.2077 17.2441 34.8926 16.6335L33.8674 21.4176C35.189 22.0231 37.6284 22.5515 40.1575 22.5781C46.0999 22.5781 49.987 19.6466 50.0053 15.1068ZM64.7686 22.2365H70L65.4336 0.399071H60.6051C59.5194 0.399071 58.6035 1.03045 58.1981 2.00118L49.7103 22.2365H55.6497L56.8288 18.9748H64.0858L64.7686 22.2365ZM58.4573 14.4991L61.4345 6.29992L63.1481 14.4991H58.4573ZM34.6597 0.399071L29.9824 22.2365H24.3263L29.0054 0.399071H34.6597V0.399071Z"
              fill="white"
            />
          </svg>
          <svg
            width="60"
            height="38"
            viewBox="0 0 60 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.mc}
          >
            <g clipPath="url(#clip0_185_4)">
              <path
                d="M57.8575 29.3099V29.4335ZM28.3571 33.6103C28.5013 33.7339 28.6558 33.8575 28.8052 33.9811C25.8644 35.9382 22.3365 37.0816 18.5408 37.0816C8.30215 37.0816 0 28.7794 0 18.5408C0 8.30215 8.30215 0 18.5408 0C22.3365 0 25.8644 1.14335 28.8052 3.10043C28.6558 3.22404 28.5013 3.34249 28.3571 3.47125C23.9897 7.27211 21.4816 12.7674 21.4816 18.5408C21.4816 24.3142 23.9846 29.8095 28.3571 33.6103V33.6103ZM41.4592 0C37.6635 0 34.1356 1.14335 31.1949 3.10043C31.3442 3.22404 31.4987 3.34249 31.6429 3.47125C36.0103 7.27211 38.5185 12.7622 38.5185 18.5408C38.5185 24.3193 36.0155 29.8095 31.6481 33.6103C31.5039 33.7339 31.3494 33.8575 31.2 33.9811C34.1408 35.9382 37.6687 37.0816 41.4644 37.0816C51.703 37.0816 60.0052 28.7794 60.0052 18.5408C60.0052 8.30215 51.6979 0 41.4592 0ZM30 3.97082C29.7631 4.15622 29.5262 4.35193 29.2996 4.55279C25.3906 7.95193 22.9185 12.9579 22.9185 18.5408C22.9185 24.1236 25.3906 29.1296 29.2996 32.5288C29.5262 32.7296 29.7631 32.9202 30 33.1107C30.2369 32.9202 30.4738 32.7296 30.7004 32.5288C34.6095 29.1296 37.0816 24.1236 37.0816 18.5408C37.0816 12.9579 34.6095 7.95193 30.7004 4.55279C30.4738 4.35193 30.2369 4.16137 30 3.97082"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_185_4">
                <rect width="60" height="37.0816" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div className={styles.brand2}>
          <div className={styles.square}></div>
          <div className={styles.triangle}></div>
        </div>
        <div className={styles.brand}>
          <svg
            width="79"
            height="20"
            viewBox="0 0 79 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.every}
          >
            <rect
              x="8.66016"
              y="3.17065"
              width="2"
              height="17"
              transform="rotate(-30 8.66016 3.17065)"
              fill="white"
            />
            <rect
              x="10.3926"
              y="2.17035"
              width="2"
              height="12"
              transform="rotate(60 10.3926 2.17035)"
              fill="white"
            />
            <rect
              x="9.42773"
              y="8.50049"
              width="2"
              height="8"
              transform="rotate(60 9.42773 8.50049)"
              fill="white"
            />
            <rect
              x="15.3926"
              y="10.8306"
              width="2"
              height="12"
              transform="rotate(60 15.3926 10.8306)"
              fill="white"
            />
            <rect
              x="21.5527"
              y="1.5"
              width="2"
              height="12"
              transform="rotate(60 21.5527 1.5)"
              fill="white"
            />
            <rect
              x="24.0527"
              y="5.83093"
              width="2"
              height="8"
              transform="rotate(60 24.0527 5.83093)"
              fill="white"
            />
            <rect
              x="26.5527"
              y="10.1611"
              width="2"
              height="12"
              transform="rotate(60 26.5527 10.1611)"
              fill="white"
            />
            <path
              d="M36.9805 7.99999V6.94999H33.1105V4.94999H36.5005V3.89999H33.1105V1.90999H36.9405V0.859985H31.8105V7.99999H36.9805Z"
              fill="white"
            />
            <path
              d="M40.5028 7.99999H41.9828L44.8128 0.859985H43.4628L41.2828 6.57999H41.2628L39.0928 0.859985H37.6828L40.5028 7.99999Z"
              fill="white"
            />
            <path
              d="M50.9161 7.99999V6.94999H47.0461V4.94999H50.4361V3.89999H47.0461V1.90999H50.8761V0.859985H45.7461V7.99999H50.9161Z"
              fill="white"
            />
            <path
              d="M53.6184 7.99999V5.32999H55.2184L56.8484 7.99999H58.2984L56.5084 5.12999C57.3184 4.86999 58.0284 4.20999 58.0284 3.09999C58.0284 1.63999 57.0484 0.859985 55.3784 0.859985H52.3184V7.99999H53.6184ZM53.6184 4.28999V1.90999H55.2284C56.2184 1.90999 56.7184 2.31999 56.7184 3.09999C56.7184 3.87999 56.2184 4.28999 55.2284 4.28999H53.6184Z"
              fill="white"
            />
            <path
              d="M62.6515 7.99999V5.06999L65.4215 0.859985H63.9715L62.0415 3.90999H62.0215L60.0415 0.859985H58.5615L61.3215 5.06999V7.99999H62.6515Z"
              fill="white"
            />
            <path
              d="M36.9805 19V17.95H33.1105V15.95H36.5005V14.9H33.1105V12.91H36.9405V11.86H31.8105V19H36.9805Z"
              fill="white"
            />
            <path
              d="M43.2628 19H44.7728L41.9328 15.2L44.4528 11.86H43.0528L41.2428 14.3L39.4428 11.86H37.9328L40.4828 15.29L37.6828 19H39.0828L41.1728 16.2L43.2628 19Z"
              fill="white"
            />
            <path
              d="M48.6573 16.37C50.3373 16.37 51.3173 15.61 51.3173 14.12C51.3173 12.63 50.3373 11.86 48.6473 11.86H45.6973V19H46.9973V16.37H48.6573ZM46.9973 15.33V12.91H48.5373C49.5173 12.91 50.0073 13.3 50.0073 14.12C50.0073 14.93 49.5173 15.33 48.5373 15.33H46.9973Z"
              fill="white"
            />
            <path
              d="M57.6153 19V17.95H53.7453V15.95H57.1353V14.9H53.7453V12.91H57.5753V11.86H52.4453V19H57.6153Z"
              fill="white"
            />
            <path
              d="M60.5676 11.86H59.0176V19H60.2876V13.47H60.3076L63.9776 19H65.2076V11.86H63.9476V16.92H63.9276L60.5676 11.86Z"
              fill="white"
            />
            <path
              d="M66.3601 16.84C66.4901 18.41 67.8001 19.12 69.5001 19.12C71.0801 19.12 72.2701 18.26 72.2701 16.96C72.2701 15.81 71.5701 15.23 70.4001 15.01L69.0201 14.74C68.2501 14.59 67.8801 14.4 67.8801 13.81C67.8801 13.18 68.4201 12.73 69.2801 12.73C70.1701 12.73 70.7901 13.12 70.8801 13.82H72.1301C71.9901 12.48 70.9701 11.74 69.2701 11.74C67.7101 11.74 66.5901 12.62 66.5901 13.89C66.5901 15.14 67.4401 15.64 68.4901 15.83L69.8001 16.07C70.6601 16.23 70.9701 16.52 70.9701 17.04C70.9701 17.76 70.3101 18.14 69.4601 18.14C68.5701 18.14 67.7301 17.81 67.6401 16.84H66.3601Z"
              fill="white"
            />
            <path
              d="M78.67 19V17.95H74.8V15.95H78.19V14.9H74.8V12.91H78.63V11.86H73.5V19H78.67Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
      <div className={styles.phone}>
        <div className={styles.card}>
          <div className={styles.name}>
            <div>
              <span className={styles.dots}>&#183;&#183;&#183;&#183;</span> 1234
            </div>
          </div>
          <div className={styles.logo}>
            <svg
              width="70"
              height="23"
              viewBox="0 0 70 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.visa}
            >
              <path
                d="M26.578 0.399071L17.4135 22.2365H11.4344L6.92449 4.80923C6.65069 3.73582 6.41262 3.34258 5.57992 2.89035C4.22039 2.15374 1.9753 1.46265 0 1.03372L0.134166 0.399071H9.75875C10.9856 0.399071 12.0884 1.21469 12.367 2.62563L14.7488 15.2619L20.6353 0.398707H26.578V0.399071ZM50.0053 15.1068C50.0293 9.3432 42.0253 9.02569 42.0804 6.45103C42.0975 5.66745 42.8445 4.83436 44.4797 4.62171C45.2901 4.51576 47.5232 4.43492 50.0559 5.599L51.0494 0.968181C49.6884 0.474805 47.9374 0 45.7586 0C40.1678 0 36.2332 2.96827 36.2 7.21858C36.1639 10.3624 39.0084 12.1167 41.1514 13.1613C43.356 14.2311 44.0958 14.9185 44.0874 15.8754C44.0717 17.3403 42.329 17.9869 40.7004 18.0124C37.8574 18.0561 36.2077 17.2441 34.8926 16.6335L33.8674 21.4176C35.189 22.0231 37.6284 22.5515 40.1575 22.5781C46.0999 22.5781 49.987 19.6466 50.0053 15.1068ZM64.7686 22.2365H70L65.4336 0.399071H60.6051C59.5194 0.399071 58.6035 1.03045 58.1981 2.00118L49.7103 22.2365H55.6497L56.8288 18.9748H64.0858L64.7686 22.2365ZM58.4573 14.4991L61.4345 6.29992L63.1481 14.4991H58.4573ZM34.6597 0.399071L29.9824 22.2365H24.3263L29.0054 0.399071H34.6597V0.399071Z"
                fill="white"
              />
            </svg>
            <svg
              width="60"
              height="38"
              viewBox="0 0 60 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.mc}
            >
              <g clipPath="url(#clip0_185_4)">
                <path
                  d="M57.8575 29.3099V29.4335ZM28.3571 33.6103C28.5013 33.7339 28.6558 33.8575 28.8052 33.9811C25.8644 35.9382 22.3365 37.0816 18.5408 37.0816C8.30215 37.0816 0 28.7794 0 18.5408C0 8.30215 8.30215 0 18.5408 0C22.3365 0 25.8644 1.14335 28.8052 3.10043C28.6558 3.22404 28.5013 3.34249 28.3571 3.47125C23.9897 7.27211 21.4816 12.7674 21.4816 18.5408C21.4816 24.3142 23.9846 29.8095 28.3571 33.6103V33.6103ZM41.4592 0C37.6635 0 34.1356 1.14335 31.1949 3.10043C31.3442 3.22404 31.4987 3.34249 31.6429 3.47125C36.0103 7.27211 38.5185 12.7622 38.5185 18.5408C38.5185 24.3193 36.0155 29.8095 31.6481 33.6103C31.5039 33.7339 31.3494 33.8575 31.2 33.9811C34.1408 35.9382 37.6687 37.0816 41.4644 37.0816C51.703 37.0816 60.0052 28.7794 60.0052 18.5408C60.0052 8.30215 51.6979 0 41.4592 0ZM30 3.97082C29.7631 4.15622 29.5262 4.35193 29.2996 4.55279C25.3906 7.95193 22.9185 12.9579 22.9185 18.5408C22.9185 24.1236 25.3906 29.1296 29.2996 32.5288C29.5262 32.7296 29.7631 32.9202 30 33.1107C30.2369 32.9202 30.4738 32.7296 30.7004 32.5288C34.6095 29.1296 37.0816 24.1236 37.0816 18.5408C37.0816 12.9579 34.6095 7.95193 30.7004 4.55279C30.4738 4.35193 30.2369 4.16137 30 3.97082"
                  fill="black"
                />
              </g>
              <defs>
                <clipPath id="clip0_185_4">
                  <rect width="60" height="37.0816" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>
          <div className={styles.brand2}>
            <div className={styles.square}></div>
            <div className={styles.triangle}></div>
          </div>
          <div className={styles.brand}>
            <svg
              width="79"
              height="20"
              viewBox="0 0 79 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className={styles.every}
            >
              <rect
                x="8.66016"
                y="3.17065"
                width="2"
                height="17"
                transform="rotate(-30 8.66016 3.17065)"
                fill="white"
              />
              <rect
                x="10.3926"
                y="2.17035"
                width="2"
                height="12"
                transform="rotate(60 10.3926 2.17035)"
                fill="white"
              />
              <rect
                x="9.42773"
                y="8.50049"
                width="2"
                height="8"
                transform="rotate(60 9.42773 8.50049)"
                fill="white"
              />
              <rect
                x="15.3926"
                y="10.8306"
                width="2"
                height="12"
                transform="rotate(60 15.3926 10.8306)"
                fill="white"
              />
              <rect
                x="21.5527"
                y="1.5"
                width="2"
                height="12"
                transform="rotate(60 21.5527 1.5)"
                fill="white"
              />
              <rect
                x="24.0527"
                y="5.83093"
                width="2"
                height="8"
                transform="rotate(60 24.0527 5.83093)"
                fill="white"
              />
              <rect
                x="26.5527"
                y="10.1611"
                width="2"
                height="12"
                transform="rotate(60 26.5527 10.1611)"
                fill="white"
              />
              <path
                d="M36.9805 7.99999V6.94999H33.1105V4.94999H36.5005V3.89999H33.1105V1.90999H36.9405V0.859985H31.8105V7.99999H36.9805Z"
                fill="white"
              />
              <path
                d="M40.5028 7.99999H41.9828L44.8128 0.859985H43.4628L41.2828 6.57999H41.2628L39.0928 0.859985H37.6828L40.5028 7.99999Z"
                fill="white"
              />
              <path
                d="M50.9161 7.99999V6.94999H47.0461V4.94999H50.4361V3.89999H47.0461V1.90999H50.8761V0.859985H45.7461V7.99999H50.9161Z"
                fill="white"
              />
              <path
                d="M53.6184 7.99999V5.32999H55.2184L56.8484 7.99999H58.2984L56.5084 5.12999C57.3184 4.86999 58.0284 4.20999 58.0284 3.09999C58.0284 1.63999 57.0484 0.859985 55.3784 0.859985H52.3184V7.99999H53.6184ZM53.6184 4.28999V1.90999H55.2284C56.2184 1.90999 56.7184 2.31999 56.7184 3.09999C56.7184 3.87999 56.2184 4.28999 55.2284 4.28999H53.6184Z"
                fill="white"
              />
              <path
                d="M62.6515 7.99999V5.06999L65.4215 0.859985H63.9715L62.0415 3.90999H62.0215L60.0415 0.859985H58.5615L61.3215 5.06999V7.99999H62.6515Z"
                fill="white"
              />
              <path
                d="M36.9805 19V17.95H33.1105V15.95H36.5005V14.9H33.1105V12.91H36.9405V11.86H31.8105V19H36.9805Z"
                fill="white"
              />
              <path
                d="M43.2628 19H44.7728L41.9328 15.2L44.4528 11.86H43.0528L41.2428 14.3L39.4428 11.86H37.9328L40.4828 15.29L37.6828 19H39.0828L41.1728 16.2L43.2628 19Z"
                fill="white"
              />
              <path
                d="M48.6573 16.37C50.3373 16.37 51.3173 15.61 51.3173 14.12C51.3173 12.63 50.3373 11.86 48.6473 11.86H45.6973V19H46.9973V16.37H48.6573ZM46.9973 15.33V12.91H48.5373C49.5173 12.91 50.0073 13.3 50.0073 14.12C50.0073 14.93 49.5173 15.33 48.5373 15.33H46.9973Z"
                fill="white"
              />
              <path
                d="M57.6153 19V17.95H53.7453V15.95H57.1353V14.9H53.7453V12.91H57.5753V11.86H52.4453V19H57.6153Z"
                fill="white"
              />
              <path
                d="M60.5676 11.86H59.0176V19H60.2876V13.47H60.3076L63.9776 19H65.2076V11.86H63.9476V16.92H63.9276L60.5676 11.86Z"
                fill="white"
              />
              <path
                d="M66.3601 16.84C66.4901 18.41 67.8001 19.12 69.5001 19.12C71.0801 19.12 72.2701 18.26 72.2701 16.96C72.2701 15.81 71.5701 15.23 70.4001 15.01L69.0201 14.74C68.2501 14.59 67.8801 14.4 67.8801 13.81C67.8801 13.18 68.4201 12.73 69.2801 12.73C70.1701 12.73 70.7901 13.12 70.8801 13.82H72.1301C71.9901 12.48 70.9701 11.74 69.2701 11.74C67.7101 11.74 66.5901 12.62 66.5901 13.89C66.5901 15.14 67.4401 15.64 68.4901 15.83L69.8001 16.07C70.6601 16.23 70.9701 16.52 70.9701 17.04C70.9701 17.76 70.3101 18.14 69.4601 18.14C68.5701 18.14 67.7301 17.81 67.6401 16.84H66.3601Z"
                fill="white"
              />
              <path
                d="M78.67 19V17.95H74.8V15.95H78.19V14.9H74.8V12.91H78.63V11.86H73.5V19H78.67Z"
                fill="white"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={styles.gradient}></div>
      <div className={styles.gradientLeft}></div>
    </div>
  </div>
);
