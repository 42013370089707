import React from "react";

import styles from "./HeroTextIntro.module.css";

export const HeroTextIntro = (): JSX.Element => (
  <div>
    <h1 className={styles.title}>
      The Most Modern Card Platform for
      <span className={styles.wordCarousel}>
        <span className={styles.wordCarouselInner}>
          <span>Debit</span>
          <span>Credit</span>
          <span>Fleet</span>
          <span>Payroll</span>
          <span>Your Business</span>
        </span>
      </span>
    </h1>
  </div>
);
