import React from "react";

import styles from "./HomeNotifications.module.css";

export const HomeNotifications = (): JSX.Element => (
  <div>
    <svg
      width="64"
      height="48"
      viewBox="0 0 64 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M42.5 23.134C43.1667 23.5189 43.1667 24.4811 42.5 24.866L12.5 42.1865C11.8333 42.5714 11 42.0903 11 41.3205L11 6.67949C11 5.90969 11.8333 5.42856 12.5 5.81347L42.5 23.134Z"
        stroke="white"
        strokeWidth="2"
        strokeDasharray="2 6"
        className={styles.dashed}
      />
      <path
        d="M62.5 23.134C63.1667 23.5189 63.1667 24.4811 62.5 24.866L29.5 43.9186C28.8333 44.3035 28 43.8224 28 43.0526L28 4.94744C28 4.17764 28.8333 3.69651 29.5 4.08142L62.5 23.134Z"
        fill="white"
        className={styles.filled}
      />
    </svg>
  </div>
);
