import React from "react";

import styles from "./LogosMarquee.module.css";

export const LogosMarquee = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.marquee}>
      <div className={styles.track}>
        <div className={styles.content}>
          <div className={styles.item}>
            <img alt="" src="img/logo-godo.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-chipper.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-bump.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-speedchain.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-tillful.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-givecard.png" />
          </div>
        </div>
      </div>
      <div className={styles.track}>
        <div className={styles.content}>
          <div className={styles.item}>
            <img alt="" src="img/logo-godo.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-chipper.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-bump.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-speedchain.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-tillful.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-givecard.png" />
          </div>
        </div>
      </div>
      <div className={styles.track}>
        <div className={styles.content}>
          <div className={styles.item}>
            <img alt="" src="img/logo-godo.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-chipper.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-bump.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-speedchain.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-tillful.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-givecard.png" />
          </div>
        </div>
      </div>
      <div className={styles.track}>
        <div className={styles.content}>
          <div className={styles.item}>
            <img alt="" src="img/logo-godo.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-chipper.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-bump.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-vendrix.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-tillful.png" />
          </div>
          <div className={styles.item}>
            <img alt="" src="img/logo-givecard.png" />
          </div>
        </div>
      </div>
    </div>
    <div className={styles.gradientLeft}></div>
    <div className={styles.gradientRight}></div>
  </div>
);
