import React from "react";

import styles from "./ProductsHero.module.css";

export const ProductsHero = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.background}>
      <div className={styles.grid}></div>
    </div>
    <div className={styles.gradientTop}></div>
    <div className={styles.gradientBottom}></div>
    <div className={styles.gradientRight}></div>
    <div className={styles.Left}></div>
    <div className={styles.blur}></div>
    <div className={styles.card}>
      <div className={styles.half1}>
        <div className={styles.column1}>
          <div className={styles.block1}></div>
          <div className={styles.block2}></div>
          <div className={styles.block3}></div>
          <div className={styles.block4}></div>
        </div>
        <div className={styles.column2}>
          <div className={styles.block5}></div>
          <div className={styles.block6}></div>
          <div className={styles.block7}></div>
          <div className={styles.block8}></div>
        </div>
        <div className={styles.column3}>
          <div className={styles.block9}></div>
          <div className={styles.block10}></div>
          <div className={styles.block11}></div>
          <div className={styles.block12}></div>
        </div>
      </div>
      <div className={styles.half2}>
        <div className={styles.column1}>
          <div className={styles.block1}></div>
          <div className={styles.block2}></div>
          <div className={styles.block3}></div>
          <div className={styles.block4}></div>
        </div>
        <div className={styles.column2}>
          <div className={styles.block5}></div>
          <div className={styles.block6}></div>
          <div className={styles.block7}></div>
          <div className={styles.block8}></div>
        </div>
        <div className={styles.column3}>
          <div className={styles.block9}></div>
          <div className={styles.block10}></div>
          <div className={styles.block11}></div>
          <div className={styles.block12}></div>
        </div>
      </div>
      <div className={styles.chip}>
        <svg
          width="42"
          height="35"
          viewBox="0 0 42 35"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.250427"
            y="0.25"
            width="41.4997"
            height="34.4997"
            rx="2.75"
            fill="#DDDDDD"
            stroke="#AAAAAA"
            strokeWidth="0.5"
          />
          <path
            d="M14.4374 11.8115L9.62493 7.87398L0 7.87398M14.4374 11.8115L14.4374 17.4989M14.4374 11.8115L20.5624 11.8115M26.6873 11.8115V17.4989M26.6873 11.8115L31.4998 7.87398L41.1247 7.87398M26.6873 11.8115H20.5624M26.6873 23.1864V17.4989M26.6873 23.1864L31.4998 27.9988L41.1247 27.9988M26.6873 23.1864H20.5624M14.4374 23.1864V17.4989M14.4374 23.1864L9.62493 27.9988H0M14.4374 23.1864H20.5624M14.4374 17.4989H0M26.6873 17.4989L41.1247 17.4989M20.5624 23.1864L20.5624 34.5613M20.5624 11.8115V0.436539"
            stroke="#AAAAAA"
            strokeWidth="0.5"
          />
        </svg>
      </div>
      <div className={styles.contactless}>
        <svg
          width="14"
          height="23"
          viewBox="0 0 14 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.85866 1.58409C6.85866 1.9131 6.982 2.2008 7.22869 2.4478C12.1222 7.34136 12.1632 15.36 7.22869 20.2535C6.73531 20.7469 6.73531 21.5282 7.22869 21.9806C7.72206 22.474 8.50339 22.474 8.9558 21.9806C14.8361 16.1004 14.7951 6.55997 8.9558 0.720684C8.46243 0.227311 7.6811 0.227311 7.22869 0.720684C6.982 0.96737 6.85866 1.29637 6.85866 1.58409Z"
            fill="white"
          />
          <path
            d="M4.10352 18.8963C4.59689 19.3896 5.37822 19.3896 5.83063 18.8963C7.84567 16.8812 8.95581 14.2084 8.95581 11.371C8.95581 8.53358 7.84545 5.86074 5.83063 3.8457C5.33726 3.35233 4.55593 3.35233 4.10352 3.8457C3.85683 4.09239 3.73349 4.38037 3.73349 4.7094C3.73349 5.03844 3.85683 5.32611 4.10352 5.57311C5.66622 7.13581 6.48858 9.19189 6.48858 11.3713C6.48858 13.5508 5.62488 15.6069 4.10352 17.1696C3.65116 17.6627 3.65116 18.444 4.10352 18.8964L4.10352 18.8963Z"
            fill="white"
          />
          <path
            d="M2.74652 6.92992C2.25315 6.43655 1.47182 6.43655 1.0194 6.92992C0.772718 7.17661 0.649375 7.46459 0.649375 7.79363C0.649375 8.12266 0.772718 8.41033 1.0194 8.65733C2.49979 10.1377 2.49979 12.5638 1.0194 14.0442C0.526031 14.5376 0.526031 15.3189 1.0194 15.7713C1.51278 16.2647 2.29411 16.2647 2.74652 15.7713C5.17263 13.3449 5.17263 9.39737 2.74652 6.93014L2.74652 6.92992Z"
            fill="white"
          />
        </svg>
      </div>
      <div className={styles.logo}>
        <svg
          width="70"
          height="23"
          viewBox="0 0 70 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.visa}
        >
          <path
            d="M26.578 0.399071L17.4135 22.2365H11.4344L6.92449 4.80923C6.65069 3.73582 6.41262 3.34258 5.57992 2.89035C4.22039 2.15374 1.9753 1.46265 0 1.03372L0.134166 0.399071H9.75875C10.9856 0.399071 12.0884 1.21469 12.367 2.62563L14.7488 15.2619L20.6353 0.398707H26.578V0.399071ZM50.0053 15.1068C50.0293 9.3432 42.0253 9.02569 42.0804 6.45103C42.0975 5.66745 42.8445 4.83436 44.4797 4.62171C45.2901 4.51576 47.5232 4.43492 50.0559 5.599L51.0494 0.968181C49.6884 0.474805 47.9374 0 45.7586 0C40.1678 0 36.2332 2.96827 36.2 7.21858C36.1639 10.3624 39.0084 12.1167 41.1514 13.1613C43.356 14.2311 44.0958 14.9185 44.0874 15.8754C44.0717 17.3403 42.329 17.9869 40.7004 18.0124C37.8574 18.0561 36.2077 17.2441 34.8926 16.6335L33.8674 21.4176C35.189 22.0231 37.6284 22.5515 40.1575 22.5781C46.0999 22.5781 49.987 19.6466 50.0053 15.1068ZM64.7686 22.2365H70L65.4336 0.399071H60.6051C59.5194 0.399071 58.6035 1.03045 58.1981 2.00118L49.7103 22.2365H55.6497L56.8288 18.9748H64.0858L64.7686 22.2365ZM58.4573 14.4991L61.4345 6.29992L63.1481 14.4991H58.4573ZM34.6597 0.399071L29.9824 22.2365H24.3263L29.0054 0.399071H34.6597V0.399071Z"
            fill="white"
          />
        </svg>
        <svg
          width="60"
          height="38"
          viewBox="0 0 60 38"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.mc}
        >
          <g clipPath="url(#clip0_185_4)">
            <path
              d="M57.8575 29.3099V29.4335ZM28.3571 33.6103C28.5013 33.7339 28.6558 33.8575 28.8052 33.9811C25.8644 35.9382 22.3365 37.0816 18.5408 37.0816C8.30215 37.0816 0 28.7794 0 18.5408C0 8.30215 8.30215 0 18.5408 0C22.3365 0 25.8644 1.14335 28.8052 3.10043C28.6558 3.22404 28.5013 3.34249 28.3571 3.47125C23.9897 7.27211 21.4816 12.7674 21.4816 18.5408C21.4816 24.3142 23.9846 29.8095 28.3571 33.6103V33.6103ZM41.4592 0C37.6635 0 34.1356 1.14335 31.1949 3.10043C31.3442 3.22404 31.4987 3.34249 31.6429 3.47125C36.0103 7.27211 38.5185 12.7622 38.5185 18.5408C38.5185 24.3193 36.0155 29.8095 31.6481 33.6103C31.5039 33.7339 31.3494 33.8575 31.2 33.9811C34.1408 35.9382 37.6687 37.0816 41.4644 37.0816C51.703 37.0816 60.0052 28.7794 60.0052 18.5408C60.0052 8.30215 51.6979 0 41.4592 0ZM30 3.97082C29.7631 4.15622 29.5262 4.35193 29.2996 4.55279C25.3906 7.95193 22.9185 12.9579 22.9185 18.5408C22.9185 24.1236 25.3906 29.1296 29.2996 32.5288C29.5262 32.7296 29.7631 32.9202 30 33.1107C30.2369 32.9202 30.4738 32.7296 30.7004 32.5288C34.6095 29.1296 37.0816 24.1236 37.0816 18.5408C37.0816 12.9579 34.6095 7.95193 30.7004 4.55279C30.4738 4.35193 30.2369 4.16137 30 3.97082"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_185_4">
              <rect width="60" height="37.0816" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
    </div>
  </div>
);
