import React from "react";

import styles from "./HomeMarquee.module.css";

export const HomeMarquee = (): JSX.Element => (
  <div className={styles.container}>
    <div className={styles.scroll}>
      <span>Grow Your Revenue. Grow Your Loyalty. Grow With Highnote.</span>
      <span>Grow Your Revenue. Grow Your Loyalty. Grow With Highnote.</span>
      <span>Grow Your Revenue. Grow Your Loyalty. Grow With Highnote.</span>
    </div>
  </div>
);
